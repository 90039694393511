import React from "react";
import Logo from "../../images/logo_22.svg";
import { Link } from "gatsby";

const CodeOfConduct = () => {
  return (
    <>
      <div id="code-of-conduct">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <img className="w-100 w-sm-75" alt="logo" src={Logo} />
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row">
            <div className="col">
              <h1 className="">Code of Conduct</h1>
              <p>
                All attendees, speakers, crew and donors at Re:factor agree to
                the following code of conduct. This code of conduct will be
                enforced throughout the event.
              </p>
              <h2>The short version</h2>
              <p>
                A conference is supposed to be a safe place. At Re:factor, we
                want out conference to be harassment-free for everyone,
                regardless of their gender, race, religion, physical appearance,
                sexual orientation or disabilities. We will not tolerate any
                harassment. Violations to this may be sanctioned and could
                result in the offender being expelled from the conference.
              </p>
              <h2>A slightly longer one</h2>
              <p>
                Harassment includes:
                <ul>
                  <li>
                    Offensive communication, for instance related gender, race,
                    religion, physical appearance, sexual orientation or
                    disabilities, regardless of form: verbal, written, body
                    language, etc
                  </li>
                  <li>Sexual images in public spaces </li>
                  <li>Deliberate intimidation, stalking or following </li>
                  <li>Harassing photography or recording </li>
                  <li>Sustained disruption of talks or other events </li>
                  <li>
                    Inappropriate physical contact, and unwelcome sexual
                    attention
                  </li>
                </ul>
              </p>
              <p>
                When any participant is asked to stop any harassment, they are
                to comply immediately.
              </p>
              <p>
                If a participant harasses any other participant, the conference
                organizers reserve the right to take any action they see fit,
                including issuing a warning, asking to apologize and removing
                the offender from the conference.
              </p>
              <p>
                If you feel like you are being harassed, please reach out to the
                organization on any of our social media channels or through{" "}
                <a href="mailto:support@re-factor.be">support@re-factor.be</a>
              </p>
              <p>
                Our organization will happily help participants resolve any
                harassment. They will also assist in anything that is necessary
                to make every participant feel safe again for the duration of
                the conference.
              </p>
              <p>
                We expect participants to follow these rules on all conference
                related channels, including our social media channels and during
                any related social events.
              </p>
              <Link to="/">Return to home</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeOfConduct;
