import * as React from "react"
import SEO from "../components/seo/Seo"
import Layout from "../components/layout/Layout"
import NavBar from "../components/navbar/NavBar";
import CodeOfConduct from "../components/codeOfConduct/CodeOfConduct";


const CodeOfConductPage = () => {
    return (
        <Layout>
            <SEO title="Code of conduct" />
            <NavBar />
            <CodeOfConduct/>
        </Layout>
    )
}

export default CodeOfConductPage
